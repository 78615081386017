import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import useAuthStore from "@/store/useAuthStore.tsx";
import { toast } from "sonner";
import AxiosInstance from "@/services/axios-instance.ts";
import ReactGA from "react-ga4";

export const useRegistrationForm = ({ cb }: { cb: () => void }) => {
  const { setToken } = useAuthStore();
  const [passwordCheck, setPasswordCheck] = useState("");

  const form = useForm<{
    email: string;
    password: string;
  }>({
    mode: "onSubmit",
    shouldFocusError: false,
    defaultValues: {
      password: "",
      email: "",
    },
  });

  const onSignUp = useCallback(async () => {
    const { email, password } = form.getValues();
    ReactGA.send({
      hitType: "event",
      eventCategory: "click",
      name: "registration_next_button_clicked",
      eventAction: "registration_next_button_clicked",
      eventLabel: "method",
      value: 1,
    });
    const url = `/signup`;
    try {
      const { data } = await AxiosInstance.post<{
        access_token: string;
        refresh_token: string;
      }>(url, {
        email: email.trim(),
        password: password.trim(),
      });

      setToken({
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
      });
      cb();

      // navigation("/trends");
    } catch (error) {
      console.error("signup error", error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }

    // form.reset({ password: "" });
    // setPasswordCheck("");
  }, [cb, form, setToken]);

  return useMemo(
    () => ({
      form,
      onSubmit: form.handleSubmit(onSignUp),
      passwordCheck,
      setPasswordCheck,
    }),
    [form, onSignUp, passwordCheck],
  );
};
